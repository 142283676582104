@import "@core/scss/core.scss";
@import "./assets/scss/styles";

p,
label {
  color: #232323;
  font-size: 16px;
}

.no-scroll {
  overflow: hidden;
}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.whatsapp-float img {
  width: 30px;
  height: 30px;
}

.whatsapp-float:hover {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3);
}
